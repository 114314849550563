import React from 'react';
import {Link} from 'gatsby';
import tw, {styled} from 'twin.macro';
// @ts-ignore
import loadable from '@loadable/component';

const Layout = loadable(() => import('@/components/layout'));
const Hero = loadable(() => import ('@/components/pages/index/hero'));
const Nav = loadable(() => import ("@/components/nav"));
const InstanceCreate = loadable(() => import ("@/components/create-instance"));
const StepProcess = loadable(() => import ("@/components/step-process"));
const Features = loadable(() => import ("@/components/features"));
const Faq = loadable(() => import ("@/components/faq"));
const StudyCta = loadable(() => import ("@/components/study_cta"));
const Footer = loadable(() => import ("@/components/footer"));
const Seo = loadable(() => import ("@/components/seo/index"));

const Wrapper = styled.div``;
const Subheader = styled.div`
	${tw`
	text-3xl
	leading-9
	font-extrabold
	text-primary-background
	sm:text-4xl
	sm:leading-10
	lg:text-5xl
	lg:leading-none
	text-center
	`}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	data: any;
	path: string;
}

const Index = (props: Props) => {
	return (
		<Layout>
			<Seo {...props}/>

			<Wrapper>
				<div className="bg-primary-background">
					<Nav/>
					<Hero/>
				</div>

				<div className="pt-24 lg:pt-32 bg-bg-background">
					<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="text-center">
							<Subheader>
								Secure VPN instances launched in minutes
							</Subheader>
							<p className="mt-4 text-xl leading-7 text-gray-600">
								We provide you with a simple configuration before launching your
								VPN service. Once installed, we logout of your instance and delete
								all credentials leaving you in full control of your server.
							</p>
							<p className="mt-4 text-xl leading-7 text-gray-600">
								<b>By design</b>, we have <b>no apps</b> but we guide you towards <b>tested,
								open-source, alternatives</b> that work just as good. No surveillance, no
								proxy backdoors or other shenanigans that brought you here in the first
								place.
							</p>
						</div>
					</div>
				</div>

				<div className="pb-2" id={"pricing"}>
					<div className="relative">
						<div className="absolute inset-0 flex flex-col">
							<div className="flex-3/4 bg-bg-background"/>
							<div className="flex-1/4"/>
						</div>
						<div className="relative max-w-screen-xl mx-auto pb-5 pt-12 px-4 sm:px-6 lg:px-6">
							<InstanceCreate/>
						</div>
					</div>
				</div>

				<div className="max-w-2xl mx-auto bg-white pb-16 mt-12 sm:pb-0 px-4 md:px-0 lg:max-w-screen-xl">
					<div className="max-w-4xl mx-auto text-center">
						<h2 className="text-3xl leading-9 font-extrabold text-primary-background">
							WireGuard or OpenVPN?
						</h2>
						<p className="mt-4 text-lg leading-7 text-gray-500">
							Not sure which one to pick? Head over to our <Link
							className={"font-extrabold hover:text-primary-background text-primary-500"}
							to={"/wireguard-versus-openvpn"}>detailed post</Link>&nbsp;
							where we discuss many pros and cons that these protocols have
							to offer. There's no bad pick but there are a few, important,
							differences.
						</p>
					</div>
				</div>

				<div className="max-w-2xl mx-auto bg-white pb-16 mt-24 sm:pb-24 sm:px-6 lg:max-w-screen-xl lg:px-8">
					<StepProcess/>
				</div>

				<div className="max-w-2xl mx-auto bg-white pb-16 mt-12 sm:pb-0 px-4 md:px-0 lg:max-w-screen-xl lg:px-8">
					<div className="max-w-4xl mx-auto text-center">
						<h2 className="text-3xl leading-9 font-extrabold text-primary-background">
							Unlimited users and zero restrictions
						</h2>
						<p className="mt-4 text-lg leading-7 text-gray-500">
							Your VM is like any other server and it can be upgraded to fit any scenario.
							We install only open source software which has no limitations in place, it is
							upgradable and highly customizable
						</p>
					</div>
					<Features/>
				</div>
			</Wrapper>

			<Wrapper>
				<div className="bg-bg-background mt-20">
					<div className="max-w-2xl mx-auto py-16 sm:py-24 px-4 md:px-0 lg:max-w-screen-xl lg:px-8">
						<Faq/>
					</div>
				</div>

				<div className="relative">
					<div className="absolute inset-0 flex flex-col">
						<div className="flex-1 bg-bg-background"/>
						<div className="flex-1"/>
					</div>
					<div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<StudyCta/>
					</div>
				</div>

				<Footer/>
			</Wrapper>
		</Layout>
	);
};

export default Index;
